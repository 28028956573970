export default function Sketch(p5) {


    let canvas;

    let frameNumber = 0;
    let widthSize = 200;
    let degree = 200;


    p5.setup = () => {
        // canvas = p5.createCanvas(400, 400);
        canvas = p5.createCanvas(p5.windowWidth, p5.windowHeight);
        // p5.saveCanvas(canvas, "fld.jpg");
        p5.frameRate(30);
        p5.smooth();
    };

    p5.draw = () => {
        p5.background(40);

        p5.translate(p5.width / 2, p5.height / 2);
        p5.push()
        for (let i = 0; i < 2; i++) {
            // for (let el = random(360); el < random(width); el += 400) {
            for (let el = 100; el < widthSize; el += 25) {
                for (let ri = degree; ri > 100 * p5.noise(frameNumber * 0.015 + el * 0.005); ri -= 1) {
                    p5.rotate(p5.random(el))
                    p5.stroke(255);
                    p5.fill(40);
                    p5.strokeWeight(1);
                    p5.rect(el * p5.cos(p5.radians(ri)), el * p5.sin(p5.radians(ri)), el * p5.cos(p5.radians(ri + 1)), el * p5.sin(p5.radians(ri + 1)));
                }


            }
        }
        frameNumber += 1;
        widthSize += 20;
        p5.pop()
    }

    p5.windowResized = () => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    }
};















